<template>
	<div data-component="deal-summaries">
		<deal-summary
			v-if="deals"
			:deal="getCurrentDeal"
		/>
		<actions
			:actions="getActions"
			@actionClick="onActionClick"
		/>
		<portal
			to="fullscreenModal"
			v-if="simulatorIsVisible"
		>
			<simulator
				:mode="getSimulatorMode"
				:gameIds="getGameIds"
				:gameStates="getGameStates"
				:playersPositions="getPlayersPositions"
				:bidExplanationsList="getBidExplanationsList"
				:bidHintsList="getBidHintsList"
				:declarerHintsList="getDeclarerHintsList"
				:openingLeadsList="getOpeningLeadsList"
				:claimParametersList="getClaimParametersList"
				@close="closeSimulator"
			/>
		</portal>
	</div>
</template>

<script>

	import DealSummary from '@/components/diagrams/DealSummary';
	import Actions from '@/components/ui/Actions';
	import actionClick from '@/mixins/actionClick';
	import { SIMULATOR_MODES } from '@/consts';

	export default {
		components: {
			DealSummary,
			Actions,
			Simulator: () => import('@/components/simulator/Simulator')
		},
		mixins: [actionClick],
		props: {
			deals: {
				type: Array,
				default: undefined
			},
			currentIndex: {
				type: [Number, undefined],
				default: undefined
			},
			mode: {
				type: [String, undefined],
				validator (value) {
					return Object.values(SIMULATOR_MODES).includes(value);
				},
				default: SIMULATOR_MODES.PREVIEW
			}
		},
		data: () => ({
			simulatorIsVisible: false,
			currentDealIndex: 0
		}),
		computed: {
			getSimulatorMode () {
				return this.mode;
			},
			getNumberOfDeals () {
				return this.deals?.length;
			},
			getCurrentDeal () {
				if (!this.deals &&
					!this.deals?.length) {
					return false;
				}
				return this.deals[this.currentDealIndex];
			},
			getGameIds () {
				return this.deals.map(deal => deal.id);
			},
			getGameStates () {
				if (!this.deals) {
					return false;
				}
				return this.deals.map((deal) => {
					return deal.deal;
				});
			},
			getPlayersPositions () {
				return this.deals.map(deal => deal.playerPosition);
			},
			getBidExplanationsList () {
				return this.deals.map(deal => deal.bidExplanations);
			},
			getBidHintsList () {
				return this.deals.map(deal => deal.bidHints);
			},
			getDeclarerHintsList () {
				return this.deals.map(deal => deal.declarerHint);
			},
			getOpeningLeadsList () {
				return this.deals.map(deal => deal.openingLead);
			},
			getClaimParametersList () {
				return this.deals.map(deal => deal.claimParameters);
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Open all deals in simulator',
							actionId: 'openSimulator'
						}
					].filter(Boolean),
					secondary: [
						(this.getNumberOfDeals > 1 ?
							{
								text: 'Prev deal',
								actionId: 'showPreviousDeal'
							} : false
						),
						(this.getNumberOfDeals > 1 ?
							{
								text: 'Next deal',
								actionId: 'showNextDeal'
							} : false
						)
					].filter(Boolean)
				};
			}
		},
		watch: {
			currentIndex: {
				handler (value) {
					if (value !== undefined) {
						this.currentDealIndex = value;
					}
				},
				immediate: true
			}
		},
		methods: {
			showNextDeal () {
				if (this.getNumberOfDeals === this.currentDealIndex + 1) {
					this.currentDealIndex = 0;
					return;
				}
				return this.currentDealIndex++;
			},
			showPreviousDeal () {
				if (this.currentDealIndex === 0) {
					this.currentDealIndex = this.getNumberOfDeals - 1;
					return false;
				}
				this.currentDealIndex--;
			},
			preview () {
				this.simulatorIsVisible = true;
			},
			closeSimulator () {
				this.simulatorIsVisible = false;
			},
			async openSimulator () {
				const gameMode = this.$store.getters['gameEngine/getGameMode']({ legacyMode: this.mode });
				const roomToken = await this.$store.dispatch('gameEngine/getRoomToken', {
					mode: gameMode,
					dealIds: this.getGameIds,
					exitRedirectUrl: window.location.href,
					dealRecordingEnabled: []
				});
				await this.$store.dispatch('gameEngine/openGameEngine', { roomToken });
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='deal-summaries'] {}

</style>
