<template>
	<div
		data-component="activity-buttons"
		v-if="!isPreview"
	>
		<activity-button
			v-for="(action, key) in getActions"
			:key="key"
			:text="action.text"
			:type="action.type"
			:data-type="action.type"
			:tourId="action.tourId"
			:isFlashing="getButtonIsFlashing(action)"
			:isInactive="getButtonIsInactive(action)"
			@actionClick="onActionClick"
			v-bind="action"
		/>
	</div>
</template>

<script>

	import ActivityButton from '@/components/activities/view/ActivityButton';
	import actionClick    from '@/mixins/actionClick';
	import routeParams    from '@/mixins/routeParams';

	export default {
		components: {
			ActivityButton
		},
		mixins: [
			actionClick,
			routeParams
		],
		props: {
			isPreview: {
				type: Boolean,
				default: false
			},
			isQuiz: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({}),
		computed: {
			getLesson () {
				return this.$store.getters['lesson/getLesson'];
			},
			getPreviousActivityRoute () {
				return this.$store.getters['lesson/getPreviousActivityRoute'];
			},
			getNextActivityRoute () {
				return this.$store.getters['lesson/getNextActivityRoute'];
			},
			getShowPreviousButton () {
				if (!this.getPreviousActivityRoute) {
					return false;
				}
				return true;
			},
			getNextButtonIsFlashing () {
				if (!this.isQuiz) {
					return false;
				}
				if (this.getCorrectAnswersAreHighlighted) {
					return true;
				}
				if (!this.getQuizIsComplete) {
					return false;
				}
				if (this.getQuizIsCompleteButIncorrect) {
					return false;
				}
				return true;
			},
			getNextButtonIsInactive () {
				if (!this.isQuiz) {
					return false;
				}
				if (this.getQuizIsComplete) {
					return false;
				}
				if (this.getCorrectAnswersAreHighlighted) {
					return false;
				}
				return true;
			},
			getShowNextButton () {
				if (!this.getNextActivityRoute) {
					return false;
				}
				if (!this.isQuiz) {
					return true;
				}
				if (this.getCorrectAnswersAreHighlighted) {
					return true;
				}
				return true;
			},
			getFinishButtonIsFlashing () {
				if (!this.isQuiz) {
					return false;
				}
				if (this.getCorrectAnswersAreHighlighted) {
					return false;
				}
				if (!this.getQuizIsComplete) {
					return false;
				}
				if (this.getQuizIsCompleteButIncorrect) {
					return false;
				}
				return true;
			},
			getFinishButtonIsInactive () {
				if (!this.isQuiz) {
					return false;
				}
				if (this.getQuizIsComplete) {
					return false;
				}
				if (this.getCorrectAnswersAreHighlighted) {
					return false;
				}
				return true;
			},
			getShowFinishButton () {
				if (!this.isQuiz) {
					if (this.getNextActivityRoute) {
						return false;
					}
					return true;
				}
				if (this.getNextActivityRoute) {
					return false;
				}
				if (this.getCorrectAnswersAreHighlighted) {
					return true;
				}
				if (!this.getQuizIsComplete) {
					return true;
				}
				return true;
			},
			getShowHighlightCorrectAnswers () {
				if (!this.isQuiz) {
					return false;
				}
				if (this.getCorrectAnswersAreHighlighted) {
					return false;
				}
				if (this.getQuizIsComplete) {
					return false;
				}
				return true;
			},
			getCorrectAnswersAreHighlighted () {
				return this.$store.getters['quiz/getCorrectAnswersAreHighlighted'];
			},
			getQuizIsComplete () {
				return this.$store.getters['quiz/getQuizIsComplete'];
			},
			getQuizIsCompleteButIncorrect () {
				return this.$store.getters['quiz/getQuizIsCompleteButIncorrect'];
			},
			getQuizIsCompleteAndCorrect () {
				return this.$store.getters['quiz/getQuizIsCompleteAndCorrect'];
			},
			getActions () {
				return [
					(this.getShowHighlightCorrectAnswers ?
						{
							text: 'Show me the answer',
							type: 'secondary',
							actionId: 'highlightCorrectAnswers',
							tourId: 'highlightCorrectAnswers',
							tourContent: 'If you’re not sure what the answer is, click here'
						} : false
					),
					(this.getQuizIsCompleteButIncorrect ?
						{
							text: 'Try again',
							type: 'secondary',
							actionId: 'tryAgain',
							tourId: 'tryAgain'
						} : false
					),
					(this.getShowPreviousButton ?
						{
							type: 'previous',
							text: 'Previous',
							actionId: 'goToPreviousActivity',
							tourId: 'goToPreviousActivity',
							tourContent: 'Click here to go back and revisit the last activity'
						} : false
					),
					(this.getShowNextButton ?
						{
							type: 'next',
							text: 'Next',
							actionId: 'goToNextActivity',
							tourId: 'goToNextActivity',
							tourContent: 'Click here to go to the next activity'
						} : false
					),
					(this.getShowFinishButton ?
						{
							type: 'finish',
							text: 'Finish lesson',
							actionId: 'returnToCourse',
							tourId: 'returnToCourse',
							tourContent: 'Click here to go back to the list of courses'
						} : false
					)
				].filter(Boolean);
			},

			getLoadingIsVisible () {
				return this.$store.getters['ui/getLoadingIsVisible'];
			}
		},
		methods: {
			getButtonIsFlashing (button) {
				if (button.type !== 'next' &&
						button.type !== 'finish' &&
						button.actionId !== 'tryAgain') {
					return false;
				}
				if (button.actionId === 'tryAgain') {
					return true;
				}
				if (button.type === 'next') {
					return this.getNextButtonIsFlashing;
				}
				return this.getFinishButtonIsFlashing;
			},
			getButtonIsInactive (button) {
				if (button.type !== 'next' &&
						button.type !== 'finish') {
					return false;
				}
				if (button.type === 'next') {
					return this.getNextButtonIsInactive;
				}
				return this.getFinishButtonIsInactive;
			},
			setActivityProgress ({ progress }) {
				this.$store.dispatch('lesson/setActivityProgress', {
					progress
				});
			},
			highlightCorrectAnswers () {
				this.setActivityProgress({
					progress: false
				});
				this.$store.commit('quiz/highlightCorrectAnswers');
			},
			tryAgain () {
				this.$store.commit('quiz/unhighlightCorrectAnswers');
				this.$store.commit('quiz/clearSelectedAnswers');
				this.setActivityProgress({
					progress: null
				});
			},
			goToNextActivity () {
				if (!this.isQuiz) {
					this.setActivityProgress({
						progress: true
					});
				}
				this.$router.push(this.getNextActivityRoute);
			},
			goToPreviousActivity () {
				this.$router.push(this.getPreviousActivityRoute);
			},
			returnToCourse () {
				const completedPrevState = this.getLesson.isComplete;
				if (!this.isQuiz) {
					this.setActivityProgress({
						progress: true
					});
				}
				this.$router.push({
					path: this.getCourseRoute,
					query: completedPrevState ? {} : { completed: true }
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='activity-buttons'] {
		display:flex;
		align-items:center;
		background-color:$c-dark-navy;
		height:rem(72);
    min-height: rem(72);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top:rem(24);

		::v-deep [data-component='activity-button'] {
			display:flex;
			align-items:center;
			justify-content:center;
			&[data-type='previous'] {
				position:absolute;
				left:rem(24);
			}
			&[data-type='next'],
			&[data-type='finish'] {
				position:absolute;
				right:rem(24);
			}
			&[data-type='secondary'] {
				margin:auto;
			}
		}
	}
</style>
